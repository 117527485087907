<template>
  <div class="page polls">
    <div class="wrapper">
      <div class="page-header">
        <h1>Public polls</h1>
        <h2>Unleashing the power of gamers to rule the geekdom with their choices! Browse all public polls now and cast your votes!</h2>
        <input type="search" placeholder="Search a poll or a creator" class="input search-input" v-model="searchTerms">
        <div class="filters">
          <FilterSelector class="filter" v-model="filter" />
        </div>
      </div>
      <div class="container">
        <div class="item" v-for="result in results" :key="result.id">
          <PollItem :poll="result" :user-votes="votes[result.id]" :user-id="user && user.id" :is-admin="user && user.admin" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import {
  collection, onSnapshot, orderBy, query, where,
} from 'firebase/firestore';
// import moment from 'moment';
import { db } from '../api';
import FilterSelector from '../components/FilterSelector.vue';
import { getFingerprint } from '../utils/fingerprint';
import PollItem from '../components/PollItem.vue';

export default {
  name: 'Polls',
  components: {
    FilterSelector,
    PollItem,
  },
  computed: {
    ...mapState(['user', 'authUser']),
    results() {
      const polls = this.polls.filter((poll) => {
        switch (this.filter) {
          case 'active':
            return !poll.hasEnded;
          case 'inactive':
            return poll.hasEnded;
          default:
            return true;
        }
      });
      if (!this.searchTerms) {
        return polls
          .slice(0, 25)
          .map((poll) => ({
            ...poll,
            votesLeft: (poll.settings?.votes_per_user || 1) - (this.votes[poll.id]?.length || 0),
          }));
      }
      const terms = this.searchTerms.trim().toLowerCase();
      return polls
        .filter((poll) => poll.title.toLowerCase().includes(terms) || poll.user?.name.toLowerCase().includes(terms))
        .slice(0, 25)
        .map((poll) => ({
          ...poll,
          votesLeft: (poll.settings?.votes_per_user || 1) - (this.votes[poll.id]?.length || 0),
        }));
    },
  },
  data() {
    return {
      loading: false,
      polls: [],
      votes: {},
      searchTerms: '',
      filter: 'none',
    };
  },
  mounted() {
    this.votesRef = onSnapshot(query(collection(db, 'votes'), where('userId', '==', this.user?.id || getFingerprint())), (docs) => {
      this.votes = docs.docs.reduce((map, doc) => {
        const vote = doc.data();
        if (!map[vote.pollId]) {
          map[vote.pollId] = [];
        }
        map[vote.pollId].push(vote.gameId);
        return map;
      }, {});
    });
    this.pollsRef = onSnapshot(query(collection(db, 'polls'), where('public', '==', true), orderBy('created', 'desc')), (docs) => {
      this.polls = docs.docs.map((doc) => {
        const data = doc.data();
        // const mom = moment(data.created.toDate());
        // const end = data.ended_at ? moment(data.ended_at.toDate()) : null;
        // const totalVotes = Object.entries(data.votes).reduce((votes, [key, count]) => {
        //   const game = data.games[key];
        //   if (game && count > 0) {
        //     votes += count;
        //   }
        //   return votes;
        // }, 0);
        return {
          ...data,
          created: data.created.toDate(),
          updated: data.updated.toDate(),
        };
      });
    });
  },
  beforeDestroy() {
    if (this.pollsRef) {
      this.pollsRef();
    }
    if (this.votesRef) {
      this.votesRef();
    }
  },
};
</script>

<style scoped>
.page {
  padding-top: 60px;
}

h1 {
  font-size: 36px;
  text-transform: uppercase;
  line-height: 1;
  margin-bottom: 8px;
}

h2 {
  font-size: 16px;
  line-height: 1;
  font-weight: normal;
}

.page-header {
  margin-bottom: 32px;
}

.filters {
  text-align: right;
}

.container {
  display: flex;
  flex-wrap: wrap;
  gap: 24px;
}

.container .item {
  width: calc(33.3333333% - 16px);
}

.container .item >>> .poll-item-container {
  height: 100%;
}

.input.search-input {
  background: none;
  border: 1px solid #F36;
  font-weight: 400;
  background: url(../assets/ic-search.svg) no-repeat 16px center/24px auto;
  padding-left: 48px;
  margin: 24px 0;
}

.input.search-input::placeholder {
  color: rgba(255, 255, 255, 0.69);
}

@media screen and (max-width: 1170px) {
  .container .item {
    width: calc(50% - 12px);
  }
}

@media screen and (max-width: 800px) {
  .container .item {
    width: 100%;
  }
}
</style>
